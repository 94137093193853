// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


$grid-breakpoints: (
  xxs: 0,
  xs: 445px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1425px,  
  xxxl:1640px,
  xxll: 1785px
) !default;

$container-max-widths: (
  xs: 415px,	
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1364px, 
  xxxl: 1366px,
  xxll: 1368px
) !default;

$grid-gutter-width: 20px !default;

$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family:   'Amatic SC', cursive;
$headings-font-weight:   400 !default;
$headings-line-height:   1.1 !default;




$body-bg: #f4f4f4;
$body-color: #222;

$font-size-base: 	1.125rem;
$h1-font-size: 		2.875rem;


$footer-bg: rgb(0,28, 74); //#023761;
$sidebar-bg: #3a3a3a;

$green: #178b00;