/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* amatic-sc-regular - latin */
@font-face {
  font-family: 'Amatic SC';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/amatic-sc-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Amatic SC Regular'), local('AmaticSC-Regular'),
       url('../fonts/amatic-sc-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/amatic-sc-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/amatic-sc-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/amatic-sc-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/amatic-sc-v11-latin-regular.svg#AmaticSC') format('svg'); /* Legacy iOS */
}

p {
    margin-top: 0;
    margin-bottom: 20px;
}

.read-more-link{font-weight:bold;}

.main {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
footer{background:$footer-bg; padding:40px 35px; color:$white; font-family: $headings-font-family; font-size:1.875rem; position: relative; z-index: 10000;}
footer li a{ color:$white; }
footer li{display:block; margin-bottom:25px;}
.footerNav{margin:0 0 65px; padding:0; list-style:none;}
/* Page */
.page-header .headerImage, .inhaltBilder img{width:100%; height: auto;}

.home .page-header{position:relative;}
.home .page-header .startHead{ position: absolute; top: 25px; width:100%; z-index:10; h1{color:$white; font-size:43px; line-height:41px; max-width:200px; margin-left:15px; span{font-size:57px; line-height:43px; display:block}}}

.home .startBox{margin-left:0px; margin-top:30px; padding-left:20px; h2{ font-size: 35px;   color: $green;    line-height: 41px;}}


.home .startContainer{background:$white;}


.anrisstext{padding:30px 0;}
.anrisstext.farbe{color:$white;}
.anrisstext.farbe a[href^="#"]{color:$white !important;}

/*.page-headerHelper{padding:0 20px;}*/
.page-headerHelper {
    padding: 0 20px 0 40px;
}

.iconHeadline{display:none;}

.box{background:$white; /*padding: 20px;*/ margin-bottom:20px;}
.box {
    padding: 20px 20px 20px 40px;
}
.inhaltBilder{margin-bottom:50px;}

.multiple .inhaltBilder{margin-bottom:0px;}
.multiple .inhaltBilder:last-child{margin-bottom:50px;}
.sliderIcon{display:inline-block; width:50px; vertical-align:top; padding:10px 0 0 10px}
.sliderIcon img{width:100%; }
.SliderIconNext{ width:30px; display:inline-block; vertical-align:middle;}
.sliderText{display:inline-block; width: calc( 100% - 90px); vertical-align:middle; padding:15px }
.carousel-item{background:$white;}

/*.smallBox{flex: 0 0 calc( 33.33333% + 5px);}
.smallBox .boxBox{margin:30px; }
.smallBox:nth-child(3n+1), .smallBox:nth-child(3n){flex: 0 0 calc( 33.33333% - 3px);}
.smallBox:nth-child(3n+1) .boxBox{margin-left:0;}
.smallBox:nth-child(3n) .boxBox{margin-right:0;}*/
.boxBox{background:$white; }
.add-gutters {
  margin-right: -$grid-gutter-width;
  margin-left: -$grid-gutter-width;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.teaserBox{background:$white; margin-bottom:20px;}
.teaserImage img{width:100%; height: auto;}
.teaserHeader { a{ padding: 0px 25px; color:$white; font-size:2.275rem; font-family: $headings-font-family; white-space: nowrap; &:hover{color:$white}}}
.teaserText{padding:15px 20px 50px 40px;}
.teaserText span{font-weight:bold;}
.teaserText a{ &:hover{color:inherit !important; text-decoration:none;}}
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}
#sidebar{width:320px; min-height:100vh; height:100%; background-color:/*$sidebar-bg*/ $white; color: /*$white*/ $sidebar-bg; }
.main-menu-nav{padding:0; margin:0; list-stlye:none;}
.main-menu-nav li, .closeX{display:block;}
.main-menu-nav li a, .closeX{color:$sidebar-bg; font-family: $headings-font-family; font-size:1.875rem; padding:5px 10px; display: block; &:hover{text-decoration:none;} }

.main-menu-nav .sub-menu { 
  margin: 0;
  padding: 0;
  display: none;
} 

.main-menu-nav > li {
  text-align: left;
}

.main-menu-nav .sub-menu li {
  text-align: right;
} 

.main-menu-nav > li > li  a {
    display: block;
  }


.nav-primary li.empty > a{display:none;}
.nav-primary li.empty .sub-menu{padding:0; margin:0;}
.nav-primary li.empty .sub-menu a{color:$footer-bg !important;}
#navi li.empty.menu-item-has-children {
    background-color: $footer-bg!important;
}
#navi li.empty .sub-menu a {
    color: $white!important;
}
.main-menu-nav li li {
    background: rgba(255,255,255,0.18);
    display: block;
	 /* line-height: 1.875rem; */
}
.toggleIcon{position:absolute; top:36px; right:62px; z-index:5000; cursor:pointer;}
#navi{background:rgba($white,1); /*margin-top:20px;*/     position: absolute;
    top: 0;
    right: 0;
    z-index: 50000;
	min-width:320px;
}
.closeX{text-align:right; cursor:pointer;}
.inhalt .row:first-child{margin-top:20px;}

.row-offcanvas .contentMain::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    background: hsla(0,0%,100%,.18);
    content: '';
    opacity: 0;
    transition: opacity 0.5s, width 0.1s 0.5s, height 0.1s 0.5s;
}

.row-offcanvas.active .contentMain::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    transition: opacity 0.5s;
}
  .row-offcanvas {
    position: relative;
    -webkit-transition: all .25s ease-out;
         -o-transition: all .25s ease-out;
            transition: all .25s ease-out;
  }

  .row-offcanvas-right {
    right: 0;
  }

  .row-offcanvas-left {
    left: 0;
  }

  .row-offcanvas-right
  .sidebar-offcanvas {
    right: -100%; /* 12 columns */
  }

  .row-offcanvas-right.active
  .sidebar-offcanvas {
    right: -320px; /* 6 columns */
  }

  .row-offcanvas-left
  .sidebar-offcanvas {
    left: -100%; /* 12 columns */
  }

  .row-offcanvas-left.active
  .sidebar-offcanvas {
    left: -320px; /* 6 columns */
  }

  .row-offcanvas-right.active {
    right: 320px; /* 6 columns */
  }

  .row-offcanvas-left.active {
    left: 320px; /* 6 columns */
  }

  .sidebar-offcanvas {
    position: absolute;
    top: 0;
    width: 320px; /* 6 columns */
  }

  .sonnenBlume, .sonnenBlumeSticky, .kopf, .stil{display:none;}
  .hiddenMobile{display:none}
  .hiddenDesktop{margin-bottom:20px;}
  
  .sonnenBlume, .sonnenBlumeSticky{position:fixed; top:0px; left:-27px; bottom:0; height:100vh; width:105px; z-index:800; display:block;}

.scroll-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 100px;
  right: 0px;
  z-index: 100000;
  color:$white;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
	background:	darken($footer-bg,15%);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s;
  
}
.scroll-top:before{position:absolute; width:20px; height:20px; top:15px; display:inline-block; left:10px; content:''; border-left:2px solid $white; border-top:2px solid $white;transform: rotate(45deg);}
.scroll-top:hover{background:lighten($footer-bg,10%);}

.scroll-top.scroll-is-visible, .scroll-top.scroll-fade-out, .no-touch .scroll-top:hover {
  transition: opacity .3s 0s, visibility 0s 0s;
}
.scroll-top.scroll-is-visible {
  visibility: visible;
  opacity: 1;
}
.scroll-top.scroll-fade-out {
   opacity: .5;
}

.no-touch .scroll-top:hover {
  background-color: $footer-bg;
  opacity: 1;
}

#cookie-notice{
	font-size: 1rem;
	line-height: 1.5rem;
	left: 0px;
}

.cn-bottom {
    bottom: 0px;
}

.cookie-notice-container {
    padding: 10px;
}
#cookie-notice .cn-button {
    margin: 5px;
    display: block;
}
.cookie-notice-container{
	text-align: left;
}  

.cookie-notice-hidden {
  width: 100px;
  left: 10px !important;
  bottom: 10px !important;
}
.cookie-notice-revoke-container {width: auto;}
.selectedCookie{position: relative; padding: 2px 12px 2px 30px !important;}
.selectedCookie:after {
    content: "";
    width: 18px;
    height: 10px;
    position: absolute;
    top: 4px;
    left: 5px;
    border: 2px solid $green;
    border-top: none;
    border-right: none;
    background: transparent;
    opacity: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


.field{
	
	background:transparent;
      padding:1rem 1rem;
      transition:all .5s ease;
      color:$body-color;
      font-size:1.25rem;
      outline:none;
     /* box-shadow: 20px 38px 34px -26px hsla(0,0%,0%,.2);*/
      border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
	  border:solid 2px $body-color;	
}

.field, .formbox label {
	margin-right: 1rem;
}


.boxFarbe .field{
	border:solid 2px $white;
	 color:$white;
}

.formbox {

	input[type="checkbox"] {
	  display:none;
	}
	
	input[type="checkbox"]+label{
		position: relative;
		padding-left: 3rem;
	}
	
	input[type="checkbox"] + label::before {
	  width: 1.25rem;
	  height: 1.25rem;
	  padding:1rem 1rem;
	  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
	  border:solid 2px $body-color;	
	  background-color: transparent;
	  display: block;
	  content: "";
	  /*float: left;
	  margin-right: 5px;*/
	  position: absolute;
	  cursor: pointer;
	  left:0;
	}
	input[type="checkbox"]:checked+label::before {
	 /* box-shadow: inset 0px 0px 0px 3px #fff;
	  background-color: #8cad2d;*/
	}
	
	input[type="checkbox"]:checked+label::after{		
		    content: "";
			width: 2rem;
			height: 1rem;
			position: absolute;
			top: 6px;
			left: 5px;
			border: 3px solid #2586ff;
			border-top: none;
			border-right: none;
			background: transparent;
			opacity: 1;
			-webkit-transform: rotate(-45deg);
			transform: rotate(-45deg);		
	}
	
}

.boxFarbe {
	
	.field::placeholder { color: rgba($white, 0.70); }
	.formbox {
		
		input[type="checkbox"] + label::before {
			 border:solid 2px $white;
		}
		
		input[type="checkbox"]:checked+label::after{
			
			border: 3px solid $white;
			border-top: none;
			border-right: none;
			
		}
		
	}
}

.fieldError { color: $red; display: none; font-size: 1rem; margin-top: 5px; }
.fieldError.showError { display: block; }
.field.fehler {border:solid 2px $red;}		

/*#groessenrechnerPopup .modal-dialog {
    -webkit-transform: translate(0,-50%);
    -o-transform: translate(0,-50%);
    transform: translate(0,-50%);
    top: 50%;
    margin: 0 auto;
}*/

/* Update Content Hub */



.contentHub {
	
	padding: 0;
	
	h3, h4 {
		text-transform: none;	
		font-size: 1.25rem;
		font-family: Open Sans,sans-serif;
		font-weight: bold;
		line-height: 1.25rem;
		text-shadow: 0px 0px 4px rgba(255,255,255,0.5);
	}
	
	h4{
		font-size: 1rem;
		font-weight: normal;
		margin-bottom: 0;
		line-height: 1rem;
	}
}
.chFeld {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	position:relative;
	font-family: Open Sans,sans-serif;
	
	.cfFeldText{
		position: absolute;
		bottom: 25px;
		right: 25px;
		text-align: right;
		left: 85px;
		
		.btn {
		  border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
		  border:solid 2px $body-color;	
		  background-color: transparent;
		  display: inline-block;
		  text-align: center;
		  margin-right: 25px;
		  color: $body-color;
		  font-size: 1.15rem;
		}
		
		&.schwarz{
			color: $white;
			
			h3, h4 { text-shadow: 0px 0px 4px rgba(0,0,0,0.5); }
			
			.btn {
				border:solid 2px $white;
				color: $white;
			}
		}
		
	}
	
	.chIcon {
		width: 80%;
    height: auto;
		margin: 0 auto;
		display: block;
		padding-top: 15px;
	}	
}

#cf_2,
#cf_6,
#cf_7 { 
	.cfFeldText { left: 0 }
}

#cf_1, 
#cf_2 {
	height: 270px;
}

#cf_3 { height: 430px;}

#cf_4 { height: 520px;}
#cf_5 { height: 180px;}

#cf_6,
#cf_7 { height: 380px;}

#cf_8 { height: 320px; }


#reset { 
	cursor: pointer;
	display: none;
	
	&:hover { box-shadow:0px 8px 4px -3px rgba(0,0,0,0.4) }

}

@include media-breakpoint-down(xs) { 
  #navi {
    width: 100%;
  }
}

@include media-breakpoint-up(xs) {
	.box {padding: 20px 30px 20px 60px;}
	.box .carousel{margin-left:-60px; margin-right:-60px;}
	.sliderIcon{ width:80px; display:inline-block; vertical-align:top;margin-top:23px; margin-left:20px; margin-right:20px;}
	.SliderIconNext{display:inline-block; vertical-align:middle; position:absolute; top:48%}
	.sliderText{display:inline-block; width: calc( 100% - 175px); vertical-align:top; margin-top:50px; padding:0}
	.box .carousel-inner { width: calc( 100% - 60px);}
	.sonnenBlume, .sonnenBlumeSticky{position:fixed; top:0px; left:-10px; bottom:0; height:100vh; width:105px; z-index:800; display:block;}
	.home .startBox{margin-left:25px; padding-left:0;}
	.teaserHeader { a{font-size:2.5rem;}}
	.home .startBox{h2{font-size:43px; color: $green; line-height:46px;}}
	.page-headerHelper{padding:0px 30px 00px 60px;}
	.sliderIcon img{width:auto;}
	/*.teaserText{padding:15px 20px 50px 0px;}*/
}
@include media-breakpoint-up(sm) {

}
@include media-breakpoint-up(md) {

	#cookie-notice{
		min-width: auto;
		max-width: 500px;
		font-size: 1rem;
		line-height: 1.5rem;
		left: 30px;
	}

	.cn-bottom {
		bottom: 30px;
	}

	.cookie-notice-container {
		padding: 20px;
	}
	#cookie-notice .cn-button {
		margin: 10px;
		display: block;
	}
	.cookie-notice-container{
		text-align: left;
	}  

	.cookie-notice-hidden {
	  width: 100px;
	  left: 10px !important;
	  bottom: 10px !important;
	}


}
@include media-breakpoint-up(lg) {
	h2{font-size:2.556rem}
	h1{font-size:4rem}
	.container {
	}
	.iconHeadline{margin-right:36px; display:inline-block;}
	.anrisstext{padding:50px 0;}
	.box{padding:60px 60px 40px; margin-bottom:60px;}
	
	.startHead .container{max-width:100%;}
	.home .page-header .startHead{ top: 60px; h1{font-size:80px; line-height:80px; max-width:calc( 100% - 90px ); margin-left:40px; span{font-size:120px; line-height:80px;}}}
	footer li {
    display: inline-block; margin-right:90px;
    margin-bottom: 25px;}
	.inhalt .row:first-child{margin-top:60px;}
	.read-more{display:none;}
	.inhalt .box .collapse{display:block !important;}
	.hiddenDesktop{display:none;}
	.hiddenMobile{display:flex;     margin-left: -30px;
    margin-right: -30px;
	}
	.inhalt{padding-left:15px; padding-right:15px;}
	.inhalt .row{ margin-left: -30px;
    margin-right: -30px;
		> .col,
	    > [class*="col-"] {
		padding-right: 30px;
		padding-left: 30px;
	  }
	}
	.inhalt .row.multiple{ margin-right: -30px;
	  margin-left: -30px;

	  > .col,
	  > [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	  }
	}
	.smallBox {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 60px;
	}
	.teaserBox{ margin-bottom:60px;}
	.inhaltUberschrift:before {
		top: -20px !important;
		left: -48px !important;
		width: 50px !important;
		height: 100px !important;   
	}
	.sliderIcon{ width:115px; display:inline-block; vertical-align:top;margin-top:23px; margin-left:20px;}
	.SliderIconNext{display:inline-block; vertical-align:middle;}
	.sliderText{display:inline-block; width: calc( 100% - 175px); vertical-align:top; margin-top:50px}
	.home .startBox h2{font-size:4rem;}
	.page-headerHelper{padding:0 0px;}
	.main-menu-nav li a, .closeX{ font-size:2.4rem;}
  
  .main-menu-nav li li a {
      font-size: 2rem;
      padding: 5px 20px;
  }
	
	.modal-dialog {
		max-width: 990px;
		margin: 30px auto;
	}
}
@include media-breakpoint-up(xl) {
	
	.home .page-header .startHead{ top: 40px; h1{font-size:140px; line-height:140px; max-width:calc( 100% - 130px ); margin-left:80px; span{font-size:200px; line-height:140px;}}}
	
	.modal-dialog {
		max-width: 1200px;
		margin: 30px auto;
	}

}
@include media-breakpoint-up(xxl) {

	.sonnenBlume{position:absolute; top:0px; left:-40px; bottom:0; height:100%; width:auto; z-index:800; display:block;}
	/*.sonnenBlumeSticky {
    position: fixed;
    top: 206px;
    left: -40px;
    bottom: 0;
    height: 100%;
    width: auto;
    z-index: 800;
    display: block;
	max-width:250px;
}
	.sonnenBlumeSticky{left:-100px;}
	.sonnenBlumeSticky img{filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.4));  max-width:250px;}
	.sonnenBlumeSticky .stil img{margin-left:-5px;}*/
	.home .sonnenBlume{top: 150px;}
	.sonnenBlumeMobile{display:block;}
	.sonnenBlumeSticky{display:block;}
	.kopf, .stil{display:none;}
	h2, h3{font-size:2.756rem}
	h1{font-size:4rem}

}
@include media-breakpoint-up(xxxl) {
.sonnenBlumeSticky {
    position: fixed;
    top: 206px;
    left: -40px;
    bottom: 0;
    height: 100%;
    width: auto;
    z-index: 800;
    display: block;
	max-width:190px;
}
.sonnenBlumeSticky img{filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.4)); /*max-width:390px;*/ /*max-width:320px;*/ /*max-width:260px*/ max-width:200px}
.sonnenBlumeSticky{left:-60px;}
.sonnenBlumeMobile{display:none;}
.kopf, .stil{display:block;}
}
@include media-breakpoint-up(xxll) {
.sonnenBlumeSticky img{filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.4)); /*max-width:390px;*/ /*max-width:320px;*//* max-width:280px*/ max-width:230px;}
.sonnenBlumeSticky{left:-40px;}
}
.menuOpen .sonnenBlume, .menuOpen .sonnenBlumeSticky{display:none;}